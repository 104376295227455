/**
 * @generated SignedSource<<d6d7f881f1f259dab02d05e3b55d30da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CountryCode = "AT" | "CH" | "DE";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartProducts_BaseDataFragment$data = {
  readonly countryCode?: CountryCode | null;
  readonly " $fragmentType": "shoppingCartProducts_BaseDataFragment";
};
export type shoppingCartProducts_BaseDataFragment$key = {
  readonly " $data"?: shoppingCartProducts_BaseDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartProducts_BaseDataFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countryCode",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartProducts_BaseDataFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "PrivateBaseData",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "BusinessBaseData",
      "abstractKey": null
    }
  ],
  "type": "baseData",
  "abstractKey": "__isbaseData"
};
})();

(node as any).hash = "b530d368158a9e01b5a1d17360056616";

export default node;

import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query shoppingCartInvoice_Query($orderId: ID!, $skip: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartInvoice_OrderFragment
			}
		}
		Viewer {
			Auth {
				...inlineAuthGuard_AuthViewerSchemaFragment
				...shoppingCartTemplate_AuthViewerSchemaFragment
			}
		}
		AccountBaseData {
			AccountBaseData {
				__typename
				...businessClientForm_BaseDataFragment
				...privateClientForm_BaseDataFragment
			}
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartInvoice_OrderFragment on Order {
		billingDetails {
			customerType
			street
			houseNumber
			city
			postalCode
			country
			phoneNumber
			... on PrivateBillingDetails {
				title
				salutation
				firstName
				lastName
				dateOfBirth
			}
			... on BusinessBillingDetails {
				company
				companyDetails
				companyType
				invoiceEmail
				taxIdentificationNumber
			}
		}
		...shoppingCartTemplate_OrderFragment
	}
`;

export const UPDATE_PRIVATE_BILLING_DETAILS_AND_INITIATE_CHECKOUT_MUTATION = graphql`
	mutation shoppingCartInvoice_UpdatePrivateBillingDetailsAndInitiateCheckoutMutation(
		$input: UpdatePrivateBillingDetailsAndInitiateCheckoutInput!
	) {
		Billing {
			updatePrivateBillingDetailsAndInitiateCheckout(input: $input) {
				clientSecret
				order {
					...shoppingCartInvoice_OrderFragment
				}
			}
		}
	}
`;

export const UPDATE_BUSINESS_BILLING_DETAILS_AND_INITIATE_CHECKOUT_MUTATION = graphql`
	mutation shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation(
		$input: UpdateBusinessBillingDetailsAndInitiateCheckoutInput!
	) {
		Billing {
			updateBusinessBillingDetailsAndInitiateCheckout(input: $input) {
				clientSecret
				order {
					...shoppingCartInvoice_OrderFragment
				}
			}
		}
	}
`;

export const countryValues = {
	CH: {
		taxNumberLabel: "Mehrwertsteuernummer (MWST-Nummer) *",
		taxNumberPlaceholder: "z.B. CHE-123456789MWST ...",
		taxIdentificationNumberLabel: "Unternehmens-Identifikationsnummer (UID-Nummer) *",
		taxIdentificationPlaceholder: "z.B. CHE-123456789 ...",
		taxIdentificationMask: "CHE-999999999",
		ibanPlaceholder: "z.B. CH93 0076 2011 6238 5295 7 ...",
		bicPlaceholder: "z.B. UBSWCHZH80A ...",
	},
	AT: {
		taxNumberLabel: "Steuernummer (St.-Nr.)*",
		taxNumberPlaceholder: "z.B. 90-123/4567 ...",
		taxIdentificationNumberLabel: "Umsatzsteuer-Identifikationsnummer (UID-Nummer.)*",
		taxIdentificationPlaceholder: "z.B. ATU12345678 ...",
		taxIdentificationMask: "ATU99999999",
		ibanPlaceholder: "AT61 1904 3002 3457 3201",
		bicPlaceholder: "GIBAATWW",
	},
	DE: {
		taxNumberLabel: "Steuernummer (St.-Nr.)*",
		taxNumberPlaceholder: "z.B. 12/345/67890 ...",
		taxIdentificationNumberLabel: "Umsatzsteuer-Identifikationsnummer (USt-IdNr.)*",
		taxIdentificationPlaceholder: "z.B. DE123456789 ...",
		taxIdentificationMask: "DE999999999",
		ibanPlaceholder: "z.B. DE89 3704 0044 0532 0130 00 ...",
		bicPlaceholder: "DEUTDEFFXXX ...",
	},
};

/**
 * @generated SignedSource<<630ccb98f25e5efe8fe125d9b1ae16aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeaveAccountInput = {
  clientMutationId?: string | null;
};
export type leaveAccountModal_anonymizeMyUserMutation$variables = {
  input: LeaveAccountInput;
};
export type leaveAccountModal_anonymizeMyUserMutation$data = {
  readonly Auth: {
    readonly leaveAccount: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type leaveAccountModal_anonymizeMyUserMutation = {
  response: leaveAccountModal_anonymizeMyUserMutation$data;
  variables: leaveAccountModal_anonymizeMyUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "LeaveAccountPayload",
        "kind": "LinkedField",
        "name": "leaveAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leaveAccountModal_anonymizeMyUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leaveAccountModal_anonymizeMyUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "284ecf5921a4a4c1ee548dae8b7f0085",
    "id": null,
    "metadata": {},
    "name": "leaveAccountModal_anonymizeMyUserMutation",
    "operationKind": "mutation",
    "text": "mutation leaveAccountModal_anonymizeMyUserMutation(\n  $input: LeaveAccountInput!\n) {\n  Auth {\n    leaveAccount(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3c6d3031e235f991b0dc029cc0fe23f9";

export default node;

import { PathParams } from "@thekeytechnology/epic-ui";
import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { BusinessClientForm } from "@components/business-client-form";
import {
	BusinessClientFormState,
	BusinessClientFormValues,
} from "@components/business-client-form/business-client-form.types";
import { getAvailableBusinessType } from "@components/business-type-dropdown";
import { InlineAuthGuard } from "@components/inline-auth-guard";
import { Message } from "@components/message";
import { PrivateClientForm } from "@components/private-client-form";
import {
	PrivateClientFormState,
	PrivateClientFormValues,
} from "@components/private-client-form/private-client-form.types";
import { RadioOption } from "@components/radio-option";
import { getAvailableSalutationType } from "@components/salutation-dropdown/salutation-dropdown.utils";
import { ShoppingCartTemplate } from "@components/shopping-cart-template";
import { ShoppingCartTemplateSkeleton } from "@components/shopping-cart-template/shopping-cart-template.skeleton";
import { withSuspense } from "@components/with-suspense";
import { useToast } from "@hooks/useToast";
import { shoppingCartInvoice_OrderFragment$key } from "@relay/shoppingCartInvoice_OrderFragment.graphql";
import { shoppingCartInvoice_Query } from "@relay/shoppingCartInvoice_Query.graphql";
import { shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation } from "@relay/shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation.graphql";
import { shoppingCartInvoice_UpdatePrivateBillingDetailsAndInitiateCheckoutMutation } from "@relay/shoppingCartInvoice_UpdatePrivateBillingDetailsAndInitiateCheckoutMutation.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { selectIsLoggedIn } from "@slices/AuthSlice";
import { H1Span, TkaH2Span } from "@themes/font-tags";
import { convertFromUTC, convertToUTC, parseDateTime } from "@utils/date-utils";
import {
	ORDER_FRAGMENT,
	QUERY,
	UPDATE_BUSINESS_BILLING_DETAILS_AND_INITIATE_CHECKOUT_MUTATION,
	UPDATE_PRIVATE_BILLING_DETAILS_AND_INITIATE_CHECKOUT_MUTATION,
} from "./shopping-cart-invoice.graphql";
import {
	ClientTypeWrapper,
	Divider,
	HeaderWrapper,
	HideablePrivateWrapper,
	HideableWrapper,
	ScreenWrapper,
} from "./shopping-cart-invoice.styles";

//TODO: add-translations
export const ShoppingCartInvoiceScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();
	const {
		node,
		Viewer: { Auth },
		AccountBaseData: { AccountBaseData },
	} = useLazyLoadQuery<shoppingCartInvoice_Query>(
		QUERY,
		{
			orderId: shoppingCartId ?? "",
			skip: !shoppingCartId,
		},
		{
			fetchPolicy: "network-only",
		},
	);

	const order = useFragment<shoppingCartInvoice_OrderFragment$key>(ORDER_FRAGMENT, node!);

	const [
		updatePrivateBillingDetailsAndInitiateCheckout,
		updatingPrivateBillingDetailsAndInitiateCheckout,
	] = useMutation<shoppingCartInvoice_UpdatePrivateBillingDetailsAndInitiateCheckoutMutation>(
		UPDATE_PRIVATE_BILLING_DETAILS_AND_INITIATE_CHECKOUT_MUTATION,
	);

	const [
		updateBusinessBillingDetailsAndInitiateCheckout,
		updatingBusinessBillingDetailsAndInitiateCheckout,
	] = useMutation<shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation>(
		UPDATE_BUSINESS_BILLING_DETAILS_AND_INITIATE_CHECKOUT_MUTATION,
	);

	const navigate = useNavigate();
	const { showError } = useToast();

	const privateFormRef = useRef<PrivateClientForm>(null);
	const businessFormRef = useRef<BusinessClientForm>(null);
	const [isBusiness, setIsBusiness] = useState(
		order?.billingDetails?.customerType === "Business" ||
			AccountBaseData?.__typename === "BusinessBaseData",
	);

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const hanldeClientTypeOnChange = (event: RadioButtonChangeEvent) => {
		setIsBusiness(event.value === "business");
	};

	const handleNextOnClick = () => {
		if (isBusiness) {
			businessFormRef.current?.submit();
		} else {
			privateFormRef.current?.submit();
		}
	};

	const handlePreviousOnClick = () => {
		navigate(-1);
	};

	const handleOnCompleted = (response: any, userType: string) => {
		if (!response) return;

		const clientSecret =
			userType === "private"
				? response.Billing.updatePrivateBillingDetailsAndInitiateCheckout.clientSecret
				: response.Billing.updateBusinessBillingDetailsAndInitiateCheckout.clientSecret;
		if (shoppingCartId && clientSecret) {
			navigate(Path.shoppingCart.withId(shoppingCartId).overview.path, {
				state: {
					clientSecret,
				},
			});
		}
	};

	const handlePrivateOnSubmit = (values: PrivateClientFormValues) => {
		if (!shoppingCartId) return;
		const utcDate = convertToUTC(values.dateOfBirth);
		updatePrivateBillingDetailsAndInitiateCheckout({
			variables: {
				input: {
					orderId: shoppingCartId,
					salutation: values.salutation,
					title: values.title,
					firstName: values.firstName,
					lastName: values.lastName,
					street: values.street,
					houseNumber: values.houseNumber,
					postalCode: values.postCode,
					city: values.city,
					country: values.country,
					phoneNumber: values.phoneNumber,
					dateOfBirth: utcDate.toISOString(),
				},
			},
			onCompleted: (response) => handleOnCompleted(response, "private"),
		});
	};

	const handleBusinessOnSubmit = (values: BusinessClientFormValues) => {
		if (!shoppingCartId) return;
		updateBusinessBillingDetailsAndInitiateCheckout({
			variables: {
				input: {
					orderId: shoppingCartId,
					company: values.company,
					companyDetails: values.recipient,
					invoiceEmail: values.email,
					street: values.street,
					houseNumber: values.houseNumber,
					postalCode: values.postCode,
					city: values.city,
					country: values.country,
					phoneNumber: values.phoneNumber,
					companyType: values.businessType,
					taxIdentificationNumber: values.taxIdentificationNumber,
				},
			},
			onCompleted: (response) => {
				handleOnCompleted(response, "business");
			},
			onError: (error) => {
				let errorMessage = error?.message;
				if (
					error?.message.includes("billing_wrong_format_for_tax_id_for_selected_country")
				) {
					errorMessage =
						"Die eingegebene Umsatzsteuer-ID entspricht nicht dem Format für das ausgewählte Land.";
				}
				showError({ summary: "Fehler", detail: errorMessage });
			},
		});
	};

	const parsedDateOfBirth = order?.billingDetails?.dateOfBirth
		? convertFromUTC(parseDateTime(order?.billingDetails?.dateOfBirth))
		: undefined;

	const privateInitialValues: PrivateClientFormState = {
		salutation: getAvailableSalutationType(order?.billingDetails?.salutation),
		title: order?.billingDetails?.title ?? "",
		firstName: order?.billingDetails?.firstName ?? "",
		lastName: order?.billingDetails?.lastName ?? "",
		street: order?.billingDetails?.street ?? "",
		houseNumber: order?.billingDetails?.houseNumber ?? "",
		postCode: order?.billingDetails?.postalCode ?? "",
		city: order?.billingDetails?.city ?? "",
		country: order?.billingDetails?.country ?? "",
		phoneNumber: order?.billingDetails?.phoneNumber ?? "",
		dateOfBirth: parsedDateOfBirth,
	};

	const businessInitialValues: BusinessClientFormState = {
		company: order?.billingDetails?.company ?? "",
		recipient: order?.billingDetails?.companyDetails ?? "",
		businessType: getAvailableBusinessType(order?.billingDetails?.companyType),
		email: order?.billingDetails?.invoiceEmail ?? "",
		street: order?.billingDetails?.street ?? "",
		houseNumber: order?.billingDetails?.houseNumber ?? "",
		postCode: order?.billingDetails?.postalCode ?? "",
		city: order?.billingDetails?.city ?? "",
		country: order?.billingDetails?.country ?? "",
		phoneNumber: order?.billingDetails?.phoneNumber ?? "",
		taxIdentificationNumber: order?.billingDetails?.taxIdentificationNumber ?? "",
	};

	const disableNext =
		updatingPrivateBillingDetailsAndInitiateCheckout ||
		updatingBusinessBillingDetailsAndInitiateCheckout ||
		!isLoggedIn;

	return (
		<ShoppingCartTemplate
			disableNext={disableNext}
			onNext={handleNextOnClick}
			onPrevious={handlePreviousOnClick}
			authViewerSchemaFragmentRef={Auth}
			orderFragmentRef={order}
		>
			<ScreenWrapper>
				<HeaderWrapper>
					<H1Span>Rechnungsdaten</H1Span>
					<Message
						highlighted
						severity="success"
						summary="Auslandsbestellungen"
						detail={
							isBusiness
								? "Du möchtest außerhalb von Deutschland, Österreich oder der Schweiz bestellen? Dann wende dich bitte an info@constellation.academy."
								: "Du möchtest außerhalb von Deutschland bestellen? Dann wende dich bitte an info@constellation.academy."
						}
					/>
				</HeaderWrapper>
				<InlineAuthGuard authViewerSchemaFragmentRef={Auth}>
					<ClientTypeWrapper>
						<RadioOption
							value="private"
							checked={!isBusiness}
							label="Privatkunde"
							onChange={hanldeClientTypeOnChange}
						/>
						<RadioOption
							value="business"
							checked={isBusiness}
							label="Geschäftskunde"
							onChange={hanldeClientTypeOnChange}
						/>
					</ClientTypeWrapper>
					<Divider />
					<HideablePrivateWrapper hidden={isBusiness}>
						<PrivateClientForm
							ref={privateFormRef}
							baseDataFragmentRef={AccountBaseData ?? undefined}
							initialValues={privateInitialValues}
							onSubmit={handlePrivateOnSubmit}
						/>
					</HideablePrivateWrapper>
					<HideableWrapper hidden={!isBusiness}>
						<TkaH2Span>Unternehmen</TkaH2Span>
						<BusinessClientForm
							ref={businessFormRef}
							baseDataFragmentRef={AccountBaseData ?? undefined}
							initialValues={businessInitialValues}
							onSubmit={handleBusinessOnSubmit}
						/>
					</HideableWrapper>
				</InlineAuthGuard>
			</ScreenWrapper>
		</ShoppingCartTemplate>
	);
};

export const ShoppingCartInvoiceScreen = withSuspense(
	ShoppingCartInvoiceScreenComponent,
	ShoppingCartTemplateSkeleton,
);

import ReactPlayer from "react-player";
import screenfull from "screenfull";
import styled from "styled-components";
import tw from "twin.macro";
import { Slider } from "@components/slider";
import { Skeleton } from "../skeleton";
import { css } from "@styled-system/css";

const FullScreenCSS = tw`
	fixed
	inset-0
	bg-shade-100
`;

export const Wrapper = styled.div<{ fullScreen: boolean }>`
	${tw`
		flex
		max-h-full
		max-w-full
		aspect-video
	`}
	@media (orientation: landscape) {
		max-height: 55dvh;
	}
	${({ fullScreen }) => fullScreen && !screenfull.isEnabled && FullScreenCSS}
	${({ fullScreen }) =>
		fullScreen &&
		tw`
		justify-center
		items-center
	`}
`;

export const CenterWrapper = styled.div<{ fullScreen: boolean }>`
	${tw`
		relative
		max-h-full
		grow
		rounded-10
		overflow-hidden
		bg-transparent
		z-10
	`}
	max-width: ${({ fullScreen }) => (fullScreen ? "calc( 100dvh * 16/9 )" : "100%")};
	${({ fullScreen }) => fullScreen && tw`rounded-none`}
`;

export const StyledReactPlayer = styled(ReactPlayer)`
	${tw`
		aspect-video
		!h-auto
		max-h-full
	`}
`;

export const Backdrop = styled.div<{ hidden?: boolean }>`
	${tw`flex
absolute
inset-0
bg-shade-80
opacity-60
duration-200
ease-out`}
	${({ hidden }) => hidden && tw`opacity-0`}
`;

export const Overlay = styled.div<{ hidden?: boolean; showBottomControls?: boolean }>`
	${tw`
		flex
		absolute
		inset-0
		flex-col
		transition-opacity
		duration-100
		ease-out
	`}
	${({ hidden }) => hidden && tw`hidden`}
	&.tka-video-controls .tka-bottom-controls {
		${({ showBottomControls }) => !showBottomControls && tw`hidden`}
		${tw`lg:hidden`}
	}
	&.tka-video-controls:hover .tka-bottom-controls {
		${tw`lg:block`}
	}
`;

export const TopControls = tw.div`
	relative
	flex
	flex-row
	justify-between
	min-h-0
	gap-24
	pl-12
	pr-12
	pt-12
	landscape:pl-20
	landscape:pr-20
	landscape:pt-16
	z-10
`;

export const TopShadow = styled.div`
	${tw`
		absolute
		top-0
		left-0
		right-0
		h-[3rem]
		md:hidden
		z-[-1]
	`}
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 98.48%);
`;

export const TranscriptsWrapper = tw.div`
	flex
	grow
	justify-center
`;

export const TopRightControls = styled.div<{ showControls?: boolean }>`
	${tw`
		md:hidden
		flex
		flex-row
		gap-24
	`}
	${({ showControls }) => !showControls && tw`hidden`}
`;

export const CenterControls = tw.div`
	flex
	justify-end
	grow
	min-h-0
	p-12
	md:p-20
`;

export const CenterPlayControlsClass = css({
	position: "absolute",
	top: "[50%]",
	left: "[50%]",
	transform: "translate(-50%, -50%)",
	display: "flex",
	alignItems: "center",
	gap: "24",
});

export const InitialPlayButton = tw.div`
flex
items-center
justify-center
w-[3.5rem]
h-[3.5rem]
bg-brand-strong-100
lg:w-[6.5rem]
lg:h-[6.5rem]
rounded-full
pl-4
md:pl-8`;

export const CenterPlayBackground = tw.div`
	flex
	items-center
	justify-center
	w-[3.5rem]
	h-[3.5rem]
	bg-shade-100
	bg-opacity-50
	rounded-[1.75rem]
`;

export const CenterRewindBackground = tw.div`
	flex
	items-center
	justify-center
	w-[2.75rem]
	h-[2.75rem]
	bg-shade-100
	bg-opacity-50
	rounded-[1.375rem]
`;

export const BottomControls = tw.div`
	relative
	flex
	flex-col
	pl-12
	pr-12
	pb-12
	md:pl-20
	md:pr-20
	md:pb-20
	z-10
`;

export const BottomShadow = styled.div`
	${tw`
		absolute
		bottom-0
		left-0
		right-0
		h-[3.5rem]
		md:h-[5.5rem]
		z-[-1]
	`}
	background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 98.48%);
`;

export const MenuWrapperAnchor = tw.div`
	relative
`;

export const MenuWrapper = tw.div`
	absolute
	flex
	bottom-0
	right-0
	max-h-full
	z-10
`;

export const SplitControlsWrapper = tw.div`
	hidden
	md:flex
	flex-row	
	justify-between
	pt-16
`;

export const ControlsWrapper = tw.div`
	flex
	flex-row
	gap-24
	items-center
`;

export const SmallVideoTime = tw.div`
	pb-12
`;

export const VolumeWrapper = tw.div`
	relative
`;

export const VolumeSliderWrapper = tw.div`
	absolute
	bottom-64
	left-[-1rem]
	bg-brand-main-100
	bg-opacity-90
	rounded-10
	p-24
`;

export const VolumeSlider = tw(Slider)`
	h-[7rem]
`;

export const LoadinAnimation = tw(Skeleton)`
	absolute
	inset-0
	z-[-10]
`;

export const CenterPlayWrapperClass = css({
	display: "flex",
	flexDir: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "16",
	position: "absolute",
	left: "[50%]",
	top: "[50%]",
	transform: "translate(-50%, -50%)",
});

import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import { Message } from "@components/message";
import { SalutationDropdown } from "@components/salutation-dropdown";
import { ValidatedInputText } from "@components/validated-input-text";
import { QUERY_FRAGMENT } from "./private-account-form.graphql";
import {
	ColSpan2,
	ColSpan4,
	InputGroupWrapper,
	RowPlaceholder,
	ShortInputsRow,
	Wrapper,
} from "./private-account-form.styles";
import {
	DropdownCountryCodes,
	PrivateAccountFormProps,
	PrivateAccountFormRef,
	PrivateAccountFormState,
} from "./private-account-form.types";
import { ResidenceDropdown } from "../residence-dropdown";
import { DROPDOWN_OPTIONS_ALL } from "../residence-dropdown/residence-dropdown.consts";

//TODO: add-translations
export const PrivateAccountForm = forwardRef<PrivateAccountFormRef, PrivateAccountFormProps>(
	function PrivateAccountForm({ baseDataFragmentRef, onSubmit }, ref) {
		const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef ?? null);

		const form = useFormik<PrivateAccountFormState>({
			initialValues: {
				salutation: query?.salutation ?? undefined,
				title: query?.title ?? "",
				firstName: query?.firstName ?? "",
				lastName: query?.lastName ?? "",
				street: query?.street ?? "",
				houseNumber: query?.houseNumber ?? "",
				postalCode: query?.postalCode ?? "",
				city: query?.city ?? "",
				countryCode: query?.countryCode ?? "DE",
				phoneNumber: query?.phoneNumber ?? "",
			},
			validateOnChange: false,
			validateOnBlur: false,
			validationSchema: Yup.object().shape({
				salutation: Yup.string()
					.test("undefined", "Bitte wähle eine Anrede aus.", (value) => {
						return value != "Unknown";
					})
					.required("Bitte wähle eine Anrede aus."),
				title: Yup.string().optional(),
				firstName: Yup.string().required("Bitte gib einen Vornamen ein."),
				lastName: Yup.string().required("Bitte gib einen Nachnamen ein."),
				street: Yup.string().required("Bitte gib eine Straße ein."),
				houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
				city: Yup.string().required("Bitte gib einen Ort ein."),
				postalCode: Yup.string()
					.when("countryCode", {
						is: "DE",
						then: Yup.string()
							.length(5, "Bitte gib eine gültige Postleitzahl ein")
							.matches(/^[0-9]{5}/, "Bitte gib eine gültige Postleitzahl ein"),
					})
					.required("Bitte gib eine Postleitzahl ein."),
				countryCode: Yup.string()
					.required("Bitte wähle ein Land aus.")
					.notOneOf(
						["OTHER"],
						"Privatkunden können derzeit nur aus Deutschland Bestellungen aufgeben.",
					),
				phoneNumber: Yup.string().optional(),
			}),
			onSubmit: (values) => {
				onSubmit?.(values);
			},
		});

		useImperativeHandle(ref, () => ({
			submit: form.submitForm,
			reset: form.resetForm,
			validate: () => form.validateForm().then((errors) => !errors),
		}));

		return (
			<Wrapper>
				<InputGroupWrapper>
					<ShortInputsRow>
						<ColSpan2>
							<SalutationDropdown
								formikConfig={form}
								name="salutation"
								label="Anrede*"
								placeholder="Anrede"
							/>
						</ColSpan2>
						<ColSpan4>
							<ValidatedInputText
								formikConfig={form}
								name="title"
								label="Titel"
								placeholder="..."
							/>
						</ColSpan4>
					</ShortInputsRow>
					<RowPlaceholder />
					<ValidatedInputText
						formikConfig={form}
						name="firstName"
						label={"Name*"}
						placeholder={"..."}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="lastName"
						label={"Nachname*"}
						placeholder={"..."}
					/>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedInputText
								formikConfig={form}
								name="street"
								label="Straße*"
								placeholder="..."
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedInputText
								formikConfig={form}
								name="houseNumber"
								label="Hausnummer*"
								placeholder="..."
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedInputText
								formikConfig={form}
								name="city"
								label="Ort/Stadt*"
								placeholder="..."
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedInputText
								formikConfig={form}
								name="postalCode"
								label="PLZ*"
								placeholder="..."
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ResidenceDropdown
						formikConfig={form}
						name="countryCode"
						label="Land"
						options={DROPDOWN_OPTIONS_ALL}
					/>
					{(form.values.countryCode as DropdownCountryCodes) === "OTHER" && (
						<Message
							highlighted
							severity="neutral"
							summary="Auslandsbestellungen"
							detail="Aktuell sind Bestellungen nur innerhalb Deutschlands möglich. Möchtest du aus dem Ausland bestellen? Dann wende dich bitte an info@constellation.academy."
						/>
					)}
					<ValidatedInputText
						formikConfig={form}
						name="phoneNumber"
						label="Telefonnummer"
						placeholder="..."
					/>
				</InputGroupWrapper>
			</Wrapper>
		);
	},
);

import styled from "styled-components";
import tw from "twin.macro";
import { TabBar } from "@components/tab-bar";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { BreakpointInPx } from "@themes/breakpoint";
import { HORIZONTAL_PADDING_SMALL, HORIZONTAL_PADDING_MEDIUM_UP } from "./video-element.const";

export const Wrapper = tw(SideMenuContentPadding)`
	flex-1
	flex
	flex-col
	max-h-full
	xl:h-full
	pb-0
`;

export const VideoPlayerWrapper = styled.div`
	${tw`
		mt-32
		flex
		flex-col
		overflow-hidden
	`}
	max-height: calc((100dvw - ${HORIZONTAL_PADDING_SMALL}rem) / (16/9) );
	@media screen and (min-width: ${BreakpointInPx.medium}px) {
		max-height: calc((100dvw - ${HORIZONTAL_PADDING_MEDIUM_UP}rem) / (16 / 9));
		max-width: fit-content;
	}
`;

export const StyledTabBar = tw(TabBar)`
	flex-1
	mt-32
`;

export const TranscriptsWrapper = tw.div`
	flex	
	flex-col	
	overflow-y-auto
	gap-16	
	pt-32
	pb-24
	md:pb-40
	max-h-[40vh]
`;

export const SubtitleItem = tw.div`
	flex
	gap-16
`;

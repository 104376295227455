import { type FC } from "react";
import { DialogTemplate } from "@components/dialog-template";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { shade0 } from "@themes/colors";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { ContentWrapper, IconCircle, IconWrapper, Wrapper } from "./delete-account-modal.styles";
import { type DeleteAccountModalProps } from "./delete-account-modal.types";

export const DeleteAccountModal: FC<DeleteAccountModalProps> = ({
	isVisible,
	onDismiss,
	onNext,
	accountName,
}) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				secondaryButtonStretch={false}
				primaryButtonLabel="Konto löschen"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={onNext}
				primaryButtonStretch={true}
			>
				<Wrapper>
					<H1Span>Willst du das Konto “{accountName}” wirklich löschen?</H1Span>
					<TkaP2Span>
						Durch das Löschen deines Kontos gehen dir die folgenden Daten für immer
						verloren und können nicht wiederhergestellt werden:
					</TkaP2Span>
					<ContentWrapper>
						<IconWrapper>
							<IconCircle>
								<Icon icon="close" sizeInRem={0.75} tkaColor={shade0} />
							</IconCircle>
							<TkaP2Span>Benutzerdaten</TkaP2Span>
						</IconWrapper>
						<IconWrapper>
							<IconCircle>
								<Icon icon="close" sizeInRem={0.75} tkaColor={shade0} />
							</IconCircle>
							<TkaP2Span>Lizenzen & Guthaben</TkaP2Span>
						</IconWrapper>
						<IconWrapper>
							<IconCircle>
								<Icon icon="close" sizeInRem={0.75} tkaColor={shade0} />
							</IconCircle>
							<TkaP2Span>Alle Mitglieder verlieren Zugriff</TkaP2Span>
						</IconWrapper>
					</ContentWrapper>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};

import { graphql } from "babel-plugin-relay/macro";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment leaveAccountModal_authViewerSchemaFragmentRef on AuthViewerSchema {
		currentUser {
			accounts {
				id
				name
			}
		}
	}
`;

export const LEAVE_ACCOUNT_MUTATION = graphql`
	mutation leaveAccountModal_anonymizeMyUserMutation($input: LeaveAccountInput!) {
		Auth {
			leaveAccount(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const DROPDOWN_OPTIONS_GERMANY_ONLY = [
	{ value: "DE", label: "Deutschland" },
	{ value: "OTHER", label: "Andere" },
];

export const DROPDOWN_OPTIONS_ALL = [
	{ value: "DE", label: "Deutschland" },
	{ value: "CH", label: "Schweiz" },
	{ value: "AT", label: "Österreich" },
	{ value: "OTHER", label: "Andere" },
];

export type ResidenceDropdownOptions = "AT" | "DE" | "CH" | "OTHER" | null | undefined;
